
import { Component, Prop, Vue } from 'vue-property-decorator';
import { addDiaryMealByOrder } from '@/api/diary';

@Component({
})
export default class extends Vue {
  @Prop({ required: true }) private order!: any;

  mounted() {
    this.form = {
      // weight: this.meal.amount,
      orderIdx: this.order.idx,
      type: this.$route.query.type,
      diaryDate: this.$route.query.diaryDate,
    };
  }

  private form = {
    // weight: 0,
    orderIdx: this.order.idx,
    type: this.$route.query.type,
    diaryDate: this.$route.query.diaryDate,
  }

  private handleAddDiaryMeal() {
    this.form = {
      ...this.form,
      orderIdx: this.order.idx,
      type: this.$route.query.type,
      diaryDate: this.$route.query.diaryDate,
    };
    addDiaryMealByOrder(this.form).then(() => {
      this.$message.success('다이어리에 추가되었습니다.');
      this.$emit('success');
    });
  }
}
