
import { Component, Vue } from 'vue-property-decorator';
import { getOrderList } from '@/api/order';
import Pagination from '@/components/Pagination/index.vue';
import FoodHistoryForm from './foodHistoryForm.vue';

@Component({
  components: {
    Pagination,
    FoodHistoryForm,
  },
})
export default class extends Vue {
  mounted() {
    this.getOrderList();
  }

  private loading = true;

  private listQuery = {
    page: 0,
    size: 10,
  }

  private totalElements = 0;

  private orderList: any = [];

  private selectedIdx: any = null;

  private selectedItem: any = null;

  private formVisible = false;

  private getOrderList() {
    this.loading = true;
    this.selectedIdx = null;
    this.selectedItem = null;
    getOrderList(this.listQuery).then((res) => {
      this.loading = false;
      this.orderList = res.data.content;
      this.totalElements = res.data.totalElements;
    });
  }

  /* eslint-disable */
  private handleSubmit() {
    if (this.selectedIdx == null) {
      this.$message.warning('다이어리에 등록할 주문내역을 선택해주세요.');
      return false;
    }
    this.selectedItem = this.orderList[this.selectedIdx];
    this.formVisible = !this.formVisible;
  }

  private handleCloseForm() {
    this.selectedIdx = null;
    this.selectedItem = null;
    this.formVisible = false;
  }

  private handleSuccessForm() {
    this.handleCloseForm();
    this.getOrderList();
  }
};
